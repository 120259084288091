/* #Site Styles
================================================== */

/*@import url(//fonts.googleapis.com/css?family=Roboto:400,300,500);*/

@import url("https://fonts.googleapis.com/css2?family=Puritan:wght@400;700&display=swap");

/* ### general ### */

:root {
  --theme-green: #1b8e78;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--theme-green);
}
p {
  font-size: 14px;
}

.tables_data{
  width: 100%;
  margin-top:40px;
}
.tables_data td{
  border:solid 1px #d4d4d4;
  padding:10px;
}
.theme-text-color {
  color: var(--theme-green) !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  /* font-family: "Segoe UI"; */
  font-size: 16px;
  color: #000;
}
input,
select,
textarea {
  /* font-family: "Segoe UI"; */
  font-size: 12px;
  color: #757575;
}
body {
  /* font-family: "Segoe UI"; */
  font-weight: normal;
}

img {
  border: 0px;
  display: block;
}
/* ul,
li {
  list-style: none;
} */
:focus {
  outline: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #00a1ff;
  transition: 0.3s;
}
.floatL {
  float: left !important;
}
.floatR {
  float: right !important;
}
.clear {
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
button {
  cursor: pointer;
}
.centerCol {
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  max-width: 1160px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

#people_wrapper {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

#mainCntr {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.main {
  width: 100%;
  height: calc(100% - 221px);
}

.PadLR {
  padding: 0 20px;
  box-sizing: border-box;
}

.headercol {
  width: 100%;
  float: left;
  box-sizing: border-box;
  background: #fff;
}
.headercol .logo {
  width: 400px;
  float: left;
  margin: 10px 0;
}
.headercol .email {
  font-size: 14px;
  float: right;
}
.headercol .email a {
  color: #000;
  padding-right: 30px;
  background: url(../images/email_icon.png) right center no-repeat;
}
.headercol a:hover {
  color: #3a5e79;
}
.headercol .rightside {
  float: right;
  width: 262px;
  padding-top: 40px;
}
.headercol.header {
  margin-bottom: 35px;
  border-bottom: 1px solid #00a650;
}
.headercol .user {
  float: right;
  padding: 0 10px;
  line-height: 40px;
}
.headercol .user span {
  width: 40px;
  height: 40px;
  float: left;
  margin: 0 10px 0 0;
  border-radius: 50px;
  display: inline-block;
  padding: 5px 0 0 0;
  overflow: hidden;
  border: 1px solid #1b8e78;
}
.headercol .user a {
  font-size: 16px;
  color: #000;
  padding-right: 15px;
  position: relative;
}
.headercol .user a::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  top: 10px;
  width: 0;
  height: 0;
  border-width: 7px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  border-style: solid;
}
.headercol .user span img {
  width: 100%;
}
.headercol.header .rightside {
  padding-top: 30px;
}

/* #naviBox ========= */
.naviBox {
  width: 100%;
  float: left;
  height: 30px;
  padding: 0;
  z-index: 555;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}
.naviBox ul {
  list-style: none;
  float: left;
  padding: 0 0;
}
.naviBox li {  
  float: left;
  font-size: 14px;
  position: relative;
  font-family: "Segoe UI Symbol";
  padding: 0 2px;
}
.naviBox li a {
  text-decoration: none;
  line-height: 30px;
  color: #fff;
  z-index: 55;
  font-weight: 500;
  position: relative;
  transition: 0.3s;
  transform: translate(0, 50px);
  
}
.naviBox li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 30px;
  background: #00a650;
  transition: width 0.5s;
}
.naviBox li:hover::after,
.naviBox li.active::after {
  width: 100%;
  color: #000;
}
.naviBox li:hover a,
.naviBox li.active a {
  color: #000;
  font-weight: 600;
}
/* .naviBox .linkbar {display: none;width: 32px;height: 32px;float: right;margin-left: 15px;text-indent: -999px;background: #00a650 url(../images/menu.svg) center center no-repeat;}
.naviBox .linkbar.fixed {background: #00a650 url(../images/close.png) center center no-repeat; } */
.headercol.header .naviBox {
  display: none;
}
.headercol .menuhum {
  display: none;
}

.bannerBox {
  width: 100%;
  float: left;
  margin-top: -30px;
  position: relative;
  position: relative;
}
.bannerBox img {
  width: 100%;
}
.bannerBox .pricecolam {
  width: 950px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
}
.bannerBox .ticket {
  padding: 15px 23px;
  margin-right: 9%;
  float: left;
  position: relative;
  font-size: 44px;
  line-height: 47px;
  border-radius: 3px 0px 0px 3px;
  background: #ffc801;
}
.bannerBox .ticket span {
  display: block;
}
.bannerBox .ticket::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: -23px;
  top: 0px;
  width: 0;
  height: 0;
  border-width: 62px 0 62px 23px;
  border-color: transparent transparent transparent #ffc801;
  border-style: solid;
}
.bannerBox .daycolam {
  float: left;
  border-radius: 3px;
  background: #fff;
  margin-right: 7.5%;
}
.bannerBox .daycolam:last-child {
  margin-right: 0;
}
.bannerBox .days {
  padding: 10px 12px 12px 12px;
  font-size: 25px;
  line-height: 26px;
  position: relative;
}
.bannerBox .days::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  bottom: -11px;
  width: 0;
  height: 0;
  border-width: 11px 51px 0 51px;
  border-color: #fff transparent transparent transparent;
  border-style: solid;
}
.bannerBox .price {
  padding: 17px 0;
  font-size: 41px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  background: #00a650;
  border-radius: 0px 0px 3px 3px;
}
.bannerBox .buyBTN {
  float: right;
  margin-top: 28px;
}
.bannerBox .buyBTN a {
  padding: 0 30px;
  line-height: 66px;
  display: block;
  border-radius: 3px;
  font-size: 30px;
  transition: 0.7s;
  color: #000;
  background: #ffc801;
}
.bannerBox .buyBTN a:hover {
  background: #000;
  color: #ffc801;
}

.applyBtn {
  float: right;
  position: relative;
  margin-top: 43px;
}
.applyBtn a {
  color: #000;
  font-weight: 600;
  transition: 0.5s;
  line-height: 35px;
  display: block;
  padding: 0 45px 0 25px;
  font-size: 16px;
  border-radius: 4px;
  background: #fff;
}
.applyBtn::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 25px;
  top: 12px;
  width: 0;
  height: 0;
  border-width: 6px 0 6px 7px;
  border-color: transparent transparent transparent #0a0909;
  border-style: solid;
}
.applyBtn a:hover {
  background: #000;
  color: #fff;
}
.applyBtn:hover::before {
  border-color: transparent transparent transparent #fff;
}

.contentBox {
  width: 100%;
  float: left;
  margin: 25px 0 30px 0;
}
.contentBox h3 {
  font-size: 30px;
  color: #000;
  /* font-weight: normal; */
  margin-bottom: 25px;
}
.contentBox p {
  font-size: 14px;
  color: #000;
  line-height: 35px;
}
.contentBox .contant {
  width: 100%;
  margin-bottom: 30px;
}
.contentBox h4 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}

.travelBox {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  background: #d7edff url(../images/bg-blue.jpg) left top repeat-x;
}
.travelBox .centerimg {
  padding: 25px 15px;
  width: 100%;
  display: flow-root;
  padding-bottom: 0;
  background: url(../images/img1.png) bottom center no-repeat;
}
.travelBox .panel {
  width: 345px;
  float: left;
}
.travelBox .left {
  width: 75px;
  float: left;
  padding-top: 10px;
}
.travelBox .right {
  width: 270px;
  float: right;
}
.travelBox h3 {
  font-size: 23px;
  color: #000;
  padding-bottom: 10px;
  font-weight: bold;
}
.travelBox p {
  font-size: 16px;
  line-height: 30px;
}
.travelBox .colam {
  width: 100%;
  float: left;
  border-bottom: 1px solid #d5d5d5;
  padding: 15px 0 20px 0;
}
.travelBox .colam:last-child {
  border-bottom: none;
}
.travelBox .left.ml {
  margin-left: -30px;
}

.templeBox {
  width: 100%;
  float: left;
}
.templeBox .textcolam {
  width: 100%;
  padding-bottom: 15px;
}
.templeBox h3 {
  font-size: 33px;
  color: #000;
  font-weight: normal;
  margin-bottom: 15px;
}
.templeBox p {
  font-size: 16px;
  color: #000;
  line-height: 33px;
  margin-bottom: 10px;
}
.templeBox .more {
  float: right;
  position: relative;
  margin-top: 0px;
}
.templeBox .more a {
  color: #f37735;
  font-weight: 600;
  transition: 0.5s;
  line-height: 20px;
  display: block;
  padding: 0 20px 0 0px;
  font-size: 18px;
}
.templeBox .more::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  top: 8px;
  width: 0;
  height: 0;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #f37735;
  border-style: solid;
}
.templeBox .more a:hover {
  color: #000;
}
.templeBox .more:hover::before {
  border-color: transparent transparent transparent #000;
}
.templeBox .list {
  margin: 0 -43px;
}
.templeBox ul {
  list-style: none;
}
.templeBox li {
  width: 33.3%;
  float: left;
  padding: 0 43px;
}
.templeBox .inner {
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.templeBox .image {
  width: 100%;
  margin-bottom: 10px;
}
.templeBox .image img {
  width: 100%;
}
.templeBox h4 {
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 13px;
}
.templeBox .inner p {
  line-height: 30px;
  margin-bottom: 20px;
}
.templeBox h5 {
  font-size: 19px;
  float: left;
  font-weight: normal;
  line-height: 30px;
  font-family: "Segoe UI Symbol";
}
.templeBox h5 span {
  display: block;
}
.templeBox .inner .more {
  padding: 0 25px;
  background: #eeeeee;
}
.templeBox .inner .more::before {
  right: 26px;
  top: 25px;
}
.templeBox .inner .more a {
  line-height: 55px;
}

.DisclaimerBox {
  width: 100%;
  float: left;
  padding: 20px 0;
  background: #eee;
  border-top: 1px solid #dce0e6;
}
.DisclaimerBox p {
  font-size: 16px;
  line-height: 33px;
  color: #000;
}
.DisclaimerBox h4 {
  font-size: 23px;
  color: #000;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0px;
}

/* Hide the browser's default checkbox */
.servicesBox {
  padding: 30px 0px;
  width: 100%;
  float: left;
  background: #d7edff;
}
.servicesBox ul {
  list-style: none;
}
.servicesBox li {
  padding-left: 48px;
  font-size: 16px;
  float: left;
  padding-right: 6%;
  font-weight: normal;
  line-height: 50px;
  background: url(../images/finence-img1.png) left center no-repeat;
}
.servicesBox li:nth-of-type(2) {
  background: url(../images/finence-img2.png) left center no-repeat;
}
.servicesBox li:nth-of-type(3) {
  background: url(../images/finence-img3.png) left center no-repeat;
}
.servicesBox li:nth-of-type(4) {
  padding-right: 0;
  background: url(../images/finence-img4.png) left center no-repeat;
}

.leftpanel {
  width: 22%;
  float: left;
  height: 100%;
  min-height: 69.5vh;
  padding: 30px 10px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 2px 20px #0000001a;
  background: #fff;
}
.rightpanel {
  width: 76%;
  float: right;
  height: 100%;
  min-height: 69.5vh;
  overflow: auto;
  margin-bottom: 30px;
  padding: 30px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 20px #0000001a;
  background: #fff;
}
.rightBox {
}

.leftNav {
  width: 100%;
}
.leftNav ul {
  list-style: none;
}
.leftNav li {
  padding: 15px 25px;
  font-size: 18px;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}
.leftNav li a {
  padding-left: 25px;
  color: #000;
  display: block;
  background: url(../images/cart-img3.png) left center no-repeat;
}
.leftNav li:nth-of-type(2) a {
  background: url(../images/cart-img1.png) left center no-repeat;
}
.leftNav li:nth-of-type(3) a {
  background: url(../images/cart-img2.png) left center no-repeat;
}
.leftNav li:hover {
  background: #eee;
}
.leftNav li.active {
  background: #1b8e78;
}
.leftNav li.active a {
  color: #fff;
  background: url(../images/cart-img3-hover.png) left center no-repeat;
}
.leftNav li.active:nth-of-type(2) a {
  background: url(../images/cart-img1-hover.png) left center no-repeat;
}
.leftNav li.active:nth-of-type(3) a {
  background: url(../images/cart-img2-hover.png) left center no-repeat;
}

.purchesBox {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  display: block;
}
.purchesBox .pass {
  width: 100%;
  float: left;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.purchesBox h3 {
  font-size: 24px;
  color: #2a917d;
  float: left;
  line-height: 30px;
}
.purchesBox h3 span {
  display: block;
  font-size: 14px;
  font-weight: normal;
}
.purchesBox .order {
  float: right;
}
.purchesBox .colR {
  padding: 5px 10px;
  margin-right: 5px;
  float: left;
}
.purchesBox .cart {
  padding: 10px;
  float: left;
  width: 44px;
  border-radius: 10px;
  margin-right: 10px;
  background: #319ee2;
}
.purchesBox .cart.green {
  background: #00b19c;
}
.purchesBox .cart.orange {
  background: #f87e7e;
}
.purchesBox .cart img {
  width: 100%;
}
.purchesBox p {
  font-size: 15px;
  color: #000;
  float: left;
  line-height: 22px;
}
.purchesBox p span {
  display: block;
  font-weight: 700;
}

.selectBox {
  width: 100%;
  margin-bottom: 17px;
}
.selectBox h3 {
  font-size: 22px;
  color: #1b8e78;
  text-align: center;
  margin-bottom: 40px;
}
.selectBox .imgselect {
  width: 100%;
  text-align: center;
}
.selectBox ul {
  list-style: none;
}
.selectBox li {
  text-align: center;
  display: inline-block;
  width: 250px;
  min-height: 250px;
  margin: 0 3%;
}
.selectBox .img {
  border-radius: 10px;
  overflow: hidden;
  min-height: 250px;
  width: 100%;
  position: relative;
}
.selectBox .img img {
  width: 100%;
  height: 100%;
  position: absolute;
}
.selectBox .img P {
  position: absolute;
  width: 100%;
  bottom: 15px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.selectBox .shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 -50px 50px 10px #00000080 inset;
}

.cartBox {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.cartBox .topcolam {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.cartBox .cancel {
  padding-left: 30px;
  position: relative;
  line-height: 30px;
  color: #37404f;
  font-size: 16px;
  float: left;
  display: block;
}
.cartBox .cancel span {
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #37404f;
  border-left: 3px solid #37404f;
  transform: rotate(-45deg);
}
.cartBox .cancel span::before {
  content: "";
  display: block;
  width: 2px;
  height: 17px;
  background-color: #37404f;
  transform: rotate(-45deg) translate(5px, 0px);
  left: 0;
  top: 0;
}
.cartBox .topcolam p {
  float: right;
  font-size: 16px;
  color: #37404f;
  line-height: 30px;
}
.cartBox ul {
  list-style: none;
  text-align: center;
}
.cartBox .cartColam {
  text-align: center;
  display: block;
  position: relative;
}
.cartBox li {
  text-align: center;
  display: inline-block;
  width: 30%;
  position: relative;
}
.cartBox .line {
  height: 1px;
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  margin: auto;
  width: 72%;
  background: #dcdcdc;
}
.cartBox .ticket {
  padding: 0 5px 0 5px;
  position: relative;
  z-index: 555;
  display: inline-block;
  background: #fff;
}
.cartBox .ticket span {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  border-radius: 50px;
  text-align: center;
  background: #737373 url(../images/icon-img4.png) center center no-repeat;
  background-size: 35%;
}
.cartBox li:nth-child(2) .ticket span {
  background: #737373 url(../images/icon-img3.png) center center no-repeat;
}
.cartBox li:nth-child(3) .ticket span {
  background: #737373 url(../images/icon-img1.png) center center no-repeat;
  background-size: 35%;
}
.cartBox .ticket p {
  padding-left: 45px;
  line-height: 40px;
  font-size: 16px;
  display: inline-block;
  color: #737373;
  position: relative;
}
.cartBox li.active .ticket span {
  background: #438c79 url(../images/icon-img4.png) center center no-repeat;
  background-size: 35%;
}

.tickeprice {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.tickeprice .type {
  padding-left: 32px;
  color: #335342;
  line-height: 30px;
  font-size: 16px;
  background: url(../images/cart-img4.png) left center no-repeat;
}
.tickeprice .listprice {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.tickeprice ul {
  list-style: none;
  text-align: center;
}
.tickeprice li {
  padding: 15px 0;
  width: 25%;
  display: inline-block;
  margin-right: 4%;
}
.tickeprice .selectticket {
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  padding: 42px 0 20px 0;
  color: #c9c9c9;
  background: url(../images/cart-img5.png) center center no-repeat;
  background-size: 60%;
}
.tickeprice p {
  font-size: 18px;
  color: #8d8d8d;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}
.tickeprice li.active {
  background: #dfeae7;
  border-radius: 10px;
}
.tickeprice li.active .selectticket {
  color: #deb13c;
  background: url(../images/cart-img5-hover.png) center center no-repeat;
  background-size: 60%;
}
.tickeprice li.active p {
  color: #438c79;
}
.tickeprice li:last-child {
  margin-right: 0;
}
.tickeprice .date {
  padding-left: 32px;
  margin-top: 20px;
  color: #335342;
  line-height: 30px;
  font-size: 16px;
  background: url(../images/date-cal.svg) left center no-repeat;
  background-size: 24px;
}
.tickeprice .note {
  margin-top: 20px;
  color: #000;
  line-height: 30px;
  font-size: 16px;
}
.tickeprice .date-cal {
  width: 280px;
  margin: 0 auto;
  margin-top: 20px;
}
.tickeprice .date-cal img {
  width: 100%;
}
.tickeprice .buttonColam {
  width: 100%;
  margin: 20px 0 0px 0;
  float: left;
}
.tickeprice .btn {
  padding: 10px 40px 10px 20px;
  text-align: center;
  margin-bottom: 30px;
  min-width: 150px;
  position: relative;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
  float: right;
  border-radius: 50px;
  display: block;
  background: #1b8e78;
}
.tickeprice .btn span {
  display: block;
  position: absolute;
  right: 45px;
  top: 22px;
  width: 9px;
  height: 9px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(-45deg);
}
.tickeprice .btn span::before {
  content: "";
  display: block;
  width: 2px;
  height: 14px;
  background-color: #fff;
  transform: rotate(-45deg) translate(4px, -2px);
  left: 0;
  top: 0;
}

.tickeprice {
}

.footerBox {
  background: #5f5f5f;
  width: 100%;
  padding: 8px 0;
  float: left;
  /* position: fixed;
  bottom: 0; */
}
.footerBox .card {
  float: right;
}
.footerBox ul {
  list-style: none;
}
.footerBox li {
  padding-right: 15px;
  float: left;
}
.footerBox .copyright {
  color: #fff;
  float: left;
  line-height: 30px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Segoe UI";
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-bottom_10 {
  margin-bottom: 10px;
}
.m-bottom_20 {
  margin-bottom: 20px;
}
.m-bottom_30 {
  margin-bottom: 30px;
}
.m-bottom_40 {
  margin-bottom: 40px;
}

.m-top_10 {
  margin-top: 10px;
}
.m-top_20 {
  margin-top: 20px;
}
.m-top_30 {
  margin-top: 30px;
}
.m-top_40 {
  margin-top: 40px;
}

.m-left_10 {
  margin-left: 10px;
}
.m-left_20 {
  margin-left: 20px;
}
.m-left_30 {
  margin-left: 30px;
}
.m-left_40 {
  margin-left: 40px;
}

.m-right_10 {
  margin-right: 10px;
}
.m-right_20 {
  margin-right: 20px;
}
.m-right_30 {
  margin-right: 30px;
}
.m-right_40 {
  margin-right: 40px;
}

.p-bottom_0 {
  padding-bottom: 0px !important;
}
.p-bottom_10 {
  padding-bottom: 10px;
}
.p-bottom_20 {
  padding-bottom: 20px;
}
.p-bottom_30 {
  padding-bottom: 30px;
}
.p-bottom_40 {
  padding-bottom: 40px;
}

.p-top_10 {
  padding-top: 10px;
}
.p-top_20 {
  padding-top: 20px;
}
.p-top_30 {
  padding-top: 30px;
}
.p-top_40 {
  padding-top: 40px;
}

.p-left_10 {
  padding-left: 10px;
}
.p-left_20 {
  padding-left: 20px;
}
.p-left_30 {
  padding-left: 30px;
}
.p-left_40 {
  padding-left: 40px;
}

.p-right_10 {
  padding-right: 10px;
}
.p-right_20 {
  padding-right: 20px;
}
.p-right_30 {
  padding-right: 30px;
}
.p-right_40 {
  padding-right: 40px;
}

.p5 {
  padding: 5px;
}
.brn {
  border: none !important;
  padding-top: 44lpx;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-70 {
  width: 70%;
}
.w-25 {
  width: 25%;
}
.w-33 {
  width: 33.3%;
}
.w-60 {
  width: 60%;
}

.left-side-container {
  border-radius: 20px;
  padding: 1rem;
  min-height: 500px;
  height: 100%;
}
.right-side-container {
  border-radius: 20px;
  padding: 1rem;
  min-height: 500px;
  height: 100%;
}

.right-side-container .icons svg {
  width: 50px;
  height: 50px;
}

.ticket-day label {
  padding: 1rem;
}

.ticket-day label:hover,
.ticket-day label.active {
  background-color: rgb(27 142 120 / 15%);
  cursor: pointer;
  border-radius: 10px;
}

.ticket-day label p {
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-top: 5px;
}

.ticket-day label svg path {
  fill: rgb(201, 201, 201);
}
.ticket-day label svg g text {
  fill: rgb(201, 201, 201);
}

.ticket-day label.active svg path {
  fill: rgb(27, 142, 120);
}
.ticket-day label.active svg g text {
  fill: rgb(229, 176, 0);
}

.btn-primary {
  background-color: var(--theme-green) !important;
  border-color: var(--theme-green) !important;
  border-radius: 20px !important;
  padding: 0.5rem 2rem !important;
}
.visitor-card {
  border-radius: 20px !important;
}
.visitor-card img {
  border-radius: 20px;
}

.visitor-card .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
}

.confirm-details table {
  width: 40%;
}

.confirm-details table tr th {
  color: #a3a3a3;
  font-weight: 400;
  padding-top: 10px;
}

.confirm-details table tr td {
  font-weight: 700;
  padding-top: 10px;
}

.left-side-container ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #000;
  padding: 1rem 1rem;
  border-radius: 50px;
  display: inline-block;
  width: 100%;
}

.left-side-container ul li a:hover {
  background-color: #f5f5f5;
}
.left-side-container ul li a.active {
  background-color: var(--theme-green);
  color: #fff !important;
}

.upload-image {
  position: relative;
  display: inline-block;
}

.upload-image span {
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 17px;
  right: 0;
  line-height: 2.4;
  z-index: 9;
}

/* Admin css Start */
.admin-login-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 50%;
  margin: auto;
}
.user-list thead tr th,
.user-list tbody tr td {
  font-size: 14px;
}
/* Admin css End */

.upload-image {
  position: relative;
}
.upload-image .photo-tooltip {
  display: none;
  position: absolute;
  top: 80%;
  width: 250px;
  z-index: 8;
}
.upload-image:hover .photo-tooltip {
  display: block;
}

.payment-methods .payment-icon {
  width: 200px;
  cursor: pointer;
  padding: 10px;
}
.payment-methods .payment-icon.active {
  border: 1px solid #00327a;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 1px #27528e;
}
/* #Media Queries
================================================== */

@media only screen and (max-width: 1150px) {
  .servicesBox li {
    padding-right: 3%;
  }
}

@media only screen and (max-width: 1023px) {
  .bannerBox .pricecolam {
    width: 100%;
    padding: 0 10px;
  }
  .bannerBox .ticket {
    font-size: 36px;
  }
  .bannerBox .ticket {
    margin-right: 6%;
  }
  .bannerBox .daycolam {
    margin-right: 4%;
  }
  .bannerBox .buyBTN a {
    padding: 0 20px;
  }
  .bannerBox .pricecolam {
    top: 29%;
  }
  .travelBox .panel {
    width: 38%;
  }
  .travelBox .left {
    width: 22%;
  }
  .travelBox .right {
    width: 75%;
  }
  .travelBox h3 {
    font-size: 18px;
  }
  .travelBox p {
    font-size: 14px;
    line-height: 27px;
  }
  .travelBox .centerimg {
    background-size: 30%;
  }
  .templeBox .list {
    margin: 0 -10px;
  }
  .templeBox li {
    padding: 0 10px;
  }
  .templeBox .inner p {
    font-size: 15px;
    line-height: 26px;
  }
  .templeBox h5 {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
  }
  .servicesBox li {
    width: 50%;
    margin-bottom: 20px;
  }
  .leftNav li {
    padding: 15px 11px;
    font-size: 15px;
  }
  .selectBox li {
    width: 220px;
    height: 220px;
  }
  .cartBox li {
    width: auto;
  }
  .cartBox .ticket span {
    width: 30px;
    height: 30px;
  }
  .cartBox .ticket p {
    padding-left: 35px;
    line-height: 32px;
  }
  .cartBox .line {
    bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .admin-login-container {
    width: 90%;
  }
  .bannerBox {
    margin-top: 0;
    height: 40%;
  }
  .headercol .logo {
    width: 115px;
    margin: 5px 0;
  }
  .headercol .logo img {
    width: 100%;
  }
  .naviBox {
    padding: 10px 5px;
    position: relative;
    width: auto;
    float: right;
    background: none;
  }
  .footerBox .copyright {
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .naviBox .menu {
    padding-top: 0px;
    position: fixed;
    left: 0;
    top: 53px;
    height: 92%;
    z-index: 99;
    width: 100%;
    background: #2a2a2a;
  }
  .naviBox ul {
    padding: 0;
    width: 100%;
  }
  .naviBox li {
    width: 100%;    
    box-sizing: border-box;
    line-height: 36px;
    border-bottom: 1px solid #424141;
  }
  .naviBox li:hover {
    background: #000;
  }
  .naviBox li a {
    color: #fff;

  }
  .naviBox li:hover::after,
  .naviBox li.active::after {
    height: 36px;
  }
  .naviBox .linkbar {
    display: none;
  }
  .headercol {
    height: auto;
    padding: 0px;
  }
  .headercol {
    border-bottom: 2px solid #00327a;
  }
  .headercol.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .headercol .rightside {
    display: none;
  }
  .travelBox .panel {
    width: 100%;
  }
  .travelBox .left.ml {
    margin-left: 0;
  }
  .travelBox .left {
    width: 60px;
  }
  .travelBox .left img {
    width: 100%;
  }
  .travelBox .right {
    width: 83%;
  }
  .travelBox .centerimg {
    background: none;
  }
  .travelBox .colam:last-child {
    border-bottom: 1px solid #d5d5d5;
  }
  .templeBox .list {
    margin: 0;
  }
  .templeBox li {
    padding: 0;
    width: 100%;
  }
  .servicesBox li {
    width: 100%;
    padding-right: 0;
  }
  .bannerBox .days::before {
    border-width: 7px 39px 0 39px;
    bottom: -7px;
  }
  .bannerBox .days {
    padding: 7px 12px 2px 12px;
    font-size: 16px;
    line-height: 18px;
  }
  .bannerBox .price {
    padding: 12px 0 4px 0;
    font-size: 18px;
    line-height: 24px;
  }
  .bannerBox .ticket::before {
    right: -12px;
    border-width: 33px 0 33px 12px;
  }
  .bannerBox .ticket {
    font-size: 21px;
    line-height: 23px;
    padding: 10px;
  }
  .footerBox .copyright {
    font-size: 11px;
    margin-top: 10px;
  }
  .bannerBox .daycolam {
    margin-right: 2%;
  }
  .bannerBox .pricecolam {
    width: 475px;
  }
  .bannerBox .buyBTN {
    margin-top: 18px;
    float: left;
  }
  .bannerBox .buyBTN a {
    padding: 0 9px;
    font-size: 18px;
    line-height: 36px;
  }
  .leftpanel {
    position: fixed;
    width: 96%;
    height: 100%;
    min-height: auto;
    top: 53px;
    left: 2%;
    z-index: 999;
    display: none;
    border-radius: 0px;
  }
  .main {
    height: auto;
  }
  .rightpanel {
    width: 100%;
    min-height: auto;
    height: auto;
    float: left;
    overflow: inherit;
  }
  .centerCol,
  #mainCntr {
    height: auto;
  }
  .headercol.header {
    margin-bottom: 15px;
  }
  .purchesBox .order {
    width: 100%;
    float: left;
  }
  .purchesBox .colR {
    padding: 5px 2px;
  }
  .purchesBox .cart {
    padding: 6px;
    width: 30px;
  }
  .purchesBox h3 {
    line-height: 26px;
    margin-bottom: 15px;
  }
  .selectBox li {
    margin-bottom: 20px;
    width: 100%;
  }
  .selectBox {
    margin-bottom: 0;
  }
  .selectBox h3 {
    font-size: 16px;
  }
  .headercol.header .naviBox {
    display: block;
  }
  .cartBox .cancel {
    text-indent: -9999px;
  }
  .cartBox .topcolam p {
    width: 92%;
    text-align: right;
  }
  .cartBox .ticket span {
    width: 40px;
    height: 40px;
  }
  .cartBox .ticket p {
    padding-left: 45px;
    line-height: 32px;
    text-indent: -9999px;
  }
  .cartBox .line {
    bottom: 13px;
  }
  .cartBox li {
    width: 32%;
  }
  .cartBox .ticket {
    padding: 0 10px 0 10px;
  }
  .tickeprice .listprice {
    width: 212px;
    margin: auto;
  }
  .tickeprice .selectticket,
  .tickeprice li.active .selectticket {
    background-size: 45%;
  }
  .tickeprice li {
    width: 100%;
    padding: 7px 0;
    margin-bottom: 15px;
  }
  .tickeprice .type {
    margin-bottom: 30px;
  }
  .contentBox p,
  .templeBox p,
  .DisclaimerBox p {
    line-height: 26px;
  }
  .headercol .menuhum {
    width: 40px;
    height: 40px;
    float: right;
    margin-left: 15px;
    position: relative;
    display: block;
    background: #00a650;
  }
  .menuhum .icon-left,
  .menuhum .icon-left:before,
  .menuhum .icon-left:after,
  .menuhum .icon-right,
  .menuhum .icon-right:before,
  .menuhum .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    background-color: #fff;
  }
  .menuhum .icon-left {
    top: 20px;
    left: 5px;
  }
  .menuhum .icon-left:before {
    content: "";
    top: -9px;
  }
  .menuhum .icon-left:after {
    content: "";
    top: 9px;
  }
  .menuhum .icon-right {
    top: 20px;
    left: 20px;
  }
  .menuhum .icon-right:before {
    content: "";
    top: -9px;
  }
  .menuhum .icon-right:after {
    content: "";
    top: 9px;
  }
  .menuhum.fixed .icon-left {
    transition-duration: 0.5s;
    background: transparent;
    left: 7px;
  }
  .menuhum.fixed .icon-left:before {
    transform: rotateZ(45deg) scaleX(1) translate(3px, 3px);
  }
  .menuhum.fixed .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1) translate(3px, -3px);
  }
  .menuhum.fixed .icon-right {
    transition-duration: 0.5s;
    background: transparent;
    left: 17px;
  }
  .menuhum.fixed .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1) translate(-3px, 3px);
  }
  .menuhum.fixed .icon-right:after {
    transform: rotateZ(45deg) scaleX(1) translate(-3px, -3px);
  }
}

@media only screen and (max-width: 475px) {
  .confirm-details table {
    width: 100%;
  }

  .confirm-details table tr th,
  .confirm-details table tr td {
    width: 50%;
    font-size: 13px;
  }

  .confirm-details table tr td {
    text-align: end;
  }
  .travelBox .left {
    width: 40px;
  }
  .travelBox .right {
    width: 80%;
  }
  .bannerBox .pricecolam {
    width: 100%;
  }
  .bannerBox .ticket {
    font-size: 16px;
    line-height: 18px;
    padding: 7px 5px;
    margin-right: 3%;
  }
  .bannerBox .ticket::before {
    border-width: 25px 0 25px 8px;
    right: -8px;
  }
  .bannerBox .days {
    padding: 7px 6px 3px 6px;
    line-height: 13px;
    font-weight: 600;
    font-size: 13px;
  }
  .bannerBox .days::before {
    border-width: 4px 26px 0 26px;
    bottom: -4px;
  }
  .bannerBox .price {
    padding: 4px 0 1px 0;
    line-height: 24px;
  }
  .bannerBox .pricecolam {
    width: 315px;
    padding: 0 3px;
  }
  .bannerBox .buyBTN a {
    padding: 0 6px;
    font-size: 13px;
    line-height: 36px;
    font-weight: 600;
  }
  .bannerBox .buyBTN {
    margin-top: 11px;
    float: right;
  }
  .purchesBox .colR {
    width: 47%;
  }
  .cartBox .topcolam p {
    width: 85%;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }
  .tickeprice {
    width: 100%;
  }
  .bannerBox img {
    min-height: 120px;
  }
}

.right-header {
}
/* //////////template css/// */

/* #Site Styles
================================================== */

/*@import url(//fonts.googleapis.com/css?family=Roboto:400,300,500);*/

@import url("https://fonts.googleapis.com/css2?family=Puritan:wght@400;700&display=swap");

/* ### general ### */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Segoe UI";
  font-size: 16px;
  color: #000;
}
input,
select,
textarea {
  font-family: "Segoe UI";
  font-size: 12px;
  color: #757575;
}
body {
  font-family: "Segoe UI";
  font-weight: normal;
}

img {
  border: 0px;
  display: block;
}
/* ul,
li {
  list-style: none;
} */
:focus {
  outline: none;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #00a1ff;
  transition: 0.3s;
}
.floatL {
  float: left !important;
}
.floatR {
  float: right !important;
}
.clear {
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
button {
  cursor: pointer;
}
.centerCol {
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
  max-width: 1160px;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

#people_wrapper {
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

#mainCntr {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.main {
  width: 100%;
  height: calc(100% - 221px);
}

.PadLR {
  padding: 0 20px;
  box-sizing: border-box;
}

.headercol {
  width: 100%;
  float: left;
  box-sizing: border-box;
  background: #fff;
}
.headercol .logo {
  width: 400px;
  float: left;
  margin: 10px 0;
}
.headercol .email {
  font-size: 14px;
  float: right;
}
.headercol .email a {
  color: #000;
  padding-right: 30px;
  background: url(../images/email_icon.png) right center no-repeat;
}
.headercol a:hover {
  color: #3a5e79;
}
.headercol .rightside {
  float: right;
  width: 262px;
  padding-top: 40px;
}
.headercol.header {
  margin-bottom: 35px;
  border-bottom: 1px solid #00a650;
}
.headercol .user {
  float: right;
  padding: 0 10px;
  line-height: 40px;
}
.headercol .user span {
  width: 40px;
  height: 40px;
  float: left;
  margin: 0 10px 0 0;
  border-radius: 50px;
  display: inline-block;
  padding: 5px 0 0 0;
  overflow: hidden;
  border: 1px solid #1b8e78;
}
.headercol .user a {
  font-size: 16px;
  color: #000;
  padding-right: 15px;
  position: relative;
}
.headercol .user a::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  top: 10px;
  width: 0;
  height: 0;
  border-width: 7px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  border-style: solid;
}
.headercol .user span img {
  width: 100%;
}
.headercol.header .rightside {
  padding-top: 30px;
}

/* #naviBox ========= */
.naviBox {
  width: 100%;
  float: left;
  height: 30px;
  padding: 0;
  z-index: 555;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
}
.naviBox ul {
  list-style: none;
  float: left;
  padding: 0 0;
}
.naviBox li {  
  float: left;
  font-size: 14px;
  position: relative;
  font-family: "Segoe UI Symbol";
}
.naviBox li a {
  text-decoration: none;
  line-height: 30px;
  color: #fff;
  z-index: 55;
  font-weight: 500;
  position: relative;
  transition: 0.3s;
  transform: translate(0, 50px);
  padding: 5px 10px;
}
.naviBox li::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 30px;
  background: #00a650;
  transition: width 0.5s;
}
.naviBox li:hover::after,
.naviBox li.active::after {
  width: 100%;
  color: #000;
}
.naviBox li:hover a,
.naviBox li.active a {
  color: #000;
  font-weight: 600;
}
/* .naviBox .linkbar {display: none;width: 32px;height: 32px;float: right;margin-left: 15px;text-indent: -999px;background: #00a650 url(../images/menu.svg) center center no-repeat;}
.naviBox .linkbar.fixed {background: #00a650 url(../images/close.png) center center no-repeat; } */
.headercol.header .naviBox {
  display: none;
}
.headercol .menuhum {
  display: none;
}

.bannerBox {
  width: 100%;
  float: left;
  margin-top: -30px;
  position: relative;
  position: relative;
}
.bannerBox img {
  width: 100%;
}
.bannerBox .pricecolam {
  width: 700px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 65%;
}
.bannerBox .ticket {
  padding: 15px 23px;
  margin-right: 9%;
  float: left;
  position: relative;
  font-size: 44px;
  line-height: 47px;
  border-radius: 3px 0px 0px 3px;
  background: #ffc801;
}
.bannerBox .ticket span {
  display: block;
}
.bannerBox .ticket::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: -23px;
  top: 0px;
  width: 0;
  height: 0;
  border-width: 62px 0 62px 23px;
  border-color: transparent transparent transparent #ffc801;
  border-style: solid;
}
.bannerBox .daycolam {
  float: left;
  border-radius: 3px;
  background: #fff;
  margin-right: 7.5%;
}
.bannerBox .daycolam:last-child {
  margin-right: 0;
}
.bannerBox .days {
  padding: 10px 12px 12px 12px;
  font-size: 25px;
  line-height: 26px;
  position: relative;
}
.bannerBox .days::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  bottom: -11px;
  width: 0;
  height: 0;
  border-width: 11px 51px 0 51px;
  border-color: #fff transparent transparent transparent;
  border-style: solid;
}
.bannerBox .price {
  padding: 17px 0;
  font-size: 34px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  background: #00a650;
  border-radius: 0px 0px 3px 3px;
}
.bannerBox .buyBTN {
  float: right;
  margin-top: 28px;
}
.bannerBox .buyBTN a {
  padding: 0 30px;
  line-height: 66px;
  display: block;
  border-radius: 3px;
  font-size: 30px;
  transition: 0.7s;
  color: #000;
  background: #ffc801;
}
.bannerBox .buyBTN a:hover {
  background: #000;
  color: #ffc801;
}

.applyBtn {
  float: right;
  position: relative;
  margin-top: 43px;
}
.applyBtn a {
  color: #000;
  font-weight: 600;
  transition: 0.5s;
  line-height: 35px;
  display: block;
  padding: 0 45px 0 25px;
  font-size: 16px;
  border-radius: 4px;
  background: #fff;
}
.applyBtn::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 25px;
  top: 12px;
  width: 0;
  height: 0;
  border-width: 6px 0 6px 7px;
  border-color: transparent transparent transparent #0a0909;
  border-style: solid;
}
.applyBtn a:hover {
  background: #000;
  color: #fff;
}
.applyBtn:hover::before {
  border-color: transparent transparent transparent #fff;
}

.contentBox {
  width: 100%;
  float: left;
  margin: 25px 0 30px 0;
}
.contentBox h3 {
  font-size: 30px;
  color: #000;
  /* font-weight: normal; */
  margin-bottom: 25px;
}
.contentBox p {
  font-size: 14px;
  color: #000;
  line-height: 35px;
}
.contentBox .contant {
  width: 100%;
  margin-bottom: 30px;
}
.contentBox h4 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}

.travelBox {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  background: #d7edff url(../images/bg-blue.jpg) left top repeat-x;
}
.travelBox .centerimg {
  padding: 25px 15px;
  width: 100%;
  display: flow-root;
  padding-bottom: 0;
  background: url(../images/img1.png) bottom center no-repeat;
}
.travelBox .panel {
  width: 345px;
  float: left;
}
.travelBox .left {
  width: 75px;
  float: left;
  padding-top: 10px;
}
.travelBox .right {
  width: 270px;
  float: right;
}
.travelBox h3 {
  font-size: 23px;
  color: #000;
  padding-bottom: 10px;
  font-weight: bold;
}
.travelBox p {
  font-size: 16px;
  line-height: 30px;
}
.travelBox .colam {
  width: 100%;
  float: left;
  border-bottom: 1px solid #d5d5d5;
  padding: 15px 0 20px 0;
}
.travelBox .colam:last-child {
  border-bottom: none;
}
.travelBox .left.ml {
  margin-left: -30px;
}

.templeBox {
  width: 100%;
  float: left;
}
.templeBox .textcolam {
  width: 100%;
  padding-bottom: 15px;
}
.templeBox h3 {
  font-size: 33px;
  color: #000;
  font-weight: normal;
  margin-bottom: 15px;
}
.templeBox p {
  font-size: 14px;
  color: #000;
  line-height: 33px;
  margin-bottom: 10px;
}
.templeBox .more {
  float: right;
  position: relative;
  margin-top: 0px;
}
.templeBox .more a {
  color: #f37735;
  font-weight: 600;
  transition: 0.5s;
  line-height: 20px;
  display: block;
  padding: 0 20px 0 0px;
  font-size: 18px;
}
.templeBox .more::before {
  content: "";
  position: absolute;
  transition: 0.5s;
  right: 0px;
  top: 8px;
  width: 0;
  height: 0;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #f37735;
  border-style: solid;
}
.templeBox .more a:hover {
  color: #000;
}
.templeBox .more:hover::before {
  border-color: transparent transparent transparent #000;
}
.templeBox .list {
  margin: 0 -43px;
}
.templeBox ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.templeBox li {
  width: 33.3%;
  float: left;
  padding: 0 43px;
}
.templeBox .inner {
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
}
.templeBox .image {
  width: 100%;
  margin-bottom: 10px;
}
.templeBox .image img {
  width: 100%;
}
.templeBox h4 {
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 13px;
}
.templeBox .inner p {
  line-height: 30px;
  margin-bottom: 20px;
}
.templeBox h5 {
  font-size: 19px;
  float: left;
  font-weight: normal;
  line-height: 30px;
  font-family: "Segoe UI Symbol";
}
.templeBox h5 span {
  display: block;
}
.templeBox .inner .more {
  padding: 0 25px;
  background: #eeeeee;
}
.templeBox .inner .more::before {
  right: 26px;
  top: 25px;
}
.templeBox .inner .more a {
  line-height: 55px;
}

.DisclaimerBox {
  width: 100%;
  float: left;
  padding: 20px 0;
  background: #eee;
  border-top: 1px solid #dce0e6;
}
.DisclaimerBox p {
  font-size: 16px;
  line-height: 33px;
  color: #000;
}
.DisclaimerBox h4 {
  font-size: 23px;
  color: #000;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0px;
}

/* Hide the browser's default checkbox */
.servicesBox {
  padding: 30px 0px;
  width: 100%;
  float: left;
  background: #d7edff;
}
.servicesBox ul {
  list-style: none;
}
.servicesBox li {
  padding-left: 48px;
  font-size: 16px;
  float: left;
  padding-right: 6%;
  font-weight: normal;
  line-height: 50px;
  background: url(../images/finence-img1.png) left center no-repeat;
}
.servicesBox li:nth-of-type(2) {
  background: url(../images/finence-img2.png) left center no-repeat;
}
.servicesBox li:nth-of-type(3) {
  background: url(../images/finence-img3.png) left center no-repeat;
}
.servicesBox li:nth-of-type(4) {
  padding-right: 0;
  background: url(../images/finence-img4.png) left center no-repeat;
}

.leftpanel {
  width: 22%;
  float: left;
  height: 100%;
  min-height: 69.5vh;
  padding: 30px 10px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 2px 20px #0000001a;
  background: #fff;
}
.rightpanel {
  width: 76%;
  float: right;
  height: 100%;
  min-height: 69.5vh;
  overflow: auto;
  margin-bottom: 30px;
  padding: 30px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 20px #0000001a;
  background: #fff;
}
.rightBox {
}

.leftNav {
  width: 100%;
}
.leftNav ul {
  list-style: none;
}
.leftNav li {
  padding: 15px 25px;
  font-size: 18px;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}
.leftNav li a {
  padding-left: 25px;
  color: #000;
  display: block;
  background: url(../images/cart-img3.png) left center no-repeat;
}
.leftNav li:nth-of-type(2) a {
  background: url(../images/cart-img1.png) left center no-repeat;
}
.leftNav li:nth-of-type(3) a {
  background: url(../images/cart-img2.png) left center no-repeat;
}
.leftNav li:hover {
  background: #eee;
}
.leftNav li.active {
  background: #1b8e78;
}
.leftNav li.active a {
  color: #fff;
  background: url(../images/cart-img3-hover.png) left center no-repeat;
}
.leftNav li.active:nth-of-type(2) a {
  background: url(../images/cart-img1-hover.png) left center no-repeat;
}
.leftNav li.active:nth-of-type(3) a {
  background: url(../images/cart-img2-hover.png) left center no-repeat;
}

.purchesBox {
  width: 100%;
  float: left;
  margin-bottom: 30px;
  display: block;
}
.purchesBox .pass {
  width: 100%;
  float: left;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.purchesBox h3 {
  font-size: 24px;
  color: #2a917d;
  float: left;
  line-height: 30px;
}
.purchesBox h3 span {
  display: block;
  font-size: 14px;
  font-weight: normal;
}
.purchesBox .order {
  float: right;
}
.purchesBox .colR {
  padding: 5px 10px;
  margin-right: 5px;
  float: left;
}
.purchesBox .cart {
  padding: 10px;
  float: left;
  width: 44px;
  border-radius: 10px;
  margin-right: 10px;
  background: #319ee2;
}
.purchesBox .cart.green {
  background: #00b19c;
}
.purchesBox .cart.orange {
  background: #f87e7e;
}
.purchesBox .cart img {
  width: 100%;
}
.purchesBox p {
  font-size: 15px;
  color: #000;
  float: left;
  line-height: 22px;
}
.purchesBox p span {
  display: block;
  font-weight: 700;
}

.selectBox {
  width: 100%;
  margin-bottom: 17px;
}
.selectBox h3 {
  font-size: 22px;
  color: #1b8e78;
  text-align: center;
  margin-bottom: 40px;
}
.selectBox .imgselect {
  width: 100%;
  text-align: center;
}
.selectBox ul {
  list-style: none;
}
.selectBox li {
  text-align: center;
  display: inline-block;
  width: 250px;
  min-height: 250px;
  margin: 0 3%;
}
.selectBox .img {
  border-radius: 10px;
  overflow: hidden;
  min-height: 250px;
  width: 100%;
  position: relative;
}
.selectBox .img img {
  width: 100%;
  height: 100%;
  position: absolute;
}
.selectBox .img P {
  position: absolute;
  width: 100%;
  bottom: 15px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.selectBox .shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  box-shadow: 0 -50px 50px 10px #00000080 inset;
}

.cartBox {
  width: 100%;
  display: block;
  margin-bottom: 30px;
}
.cartBox .topcolam {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.cartBox .cancel {
  padding-left: 30px;
  position: relative;
  line-height: 30px;
  color: #37404f;
  font-size: 16px;
  float: left;
  display: block;
}
.cartBox .cancel span {
  display: block;
  position: absolute;
  left: 0;
  top: 12px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #37404f;
  border-left: 3px solid #37404f;
  transform: rotate(-45deg);
}
.cartBox .cancel span::before {
  content: "";
  display: block;
  width: 2px;
  height: 17px;
  background-color: #37404f;
  transform: rotate(-45deg) translate(5px, 0px);
  left: 0;
  top: 0;
}
.cartBox .topcolam p {
  float: right;
  font-size: 16px;
  color: #37404f;
  line-height: 30px;
}
.cartBox ul {
  list-style: none;
  text-align: center;
}
.cartBox .cartColam {
  text-align: center;
  display: block;
  position: relative;
}
.cartBox li {
  text-align: center;
  display: inline-block;
  width: 30%;
  position: relative;
}
.cartBox .line {
  height: 1px;
  position: absolute;
  bottom: 17px;
  left: 0;
  right: 0;
  margin: auto;
  width: 72%;
  background: #dcdcdc;
}
.cartBox .ticket {
  padding: 0 5px 0 5px;
  position: relative;
  z-index: 555;
  display: inline-block;
  background: #fff;
}
.cartBox .ticket span {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  border-radius: 50px;
  text-align: center;
  background: #737373 url(../images/icon-img4.png) center center no-repeat;
  background-size: 35%;
}
.cartBox li:nth-child(2) .ticket span {
  background: #737373 url(../images/icon-img3.png) center center no-repeat;
}
.cartBox li:nth-child(3) .ticket span {
  background: #737373 url(../images/icon-img1.png) center center no-repeat;
  background-size: 35%;
}
.cartBox .ticket p {
  padding-left: 45px;
  line-height: 40px;
  font-size: 16px;
  display: inline-block;
  color: #737373;
  position: relative;
}
.cartBox li.active .ticket span {
  background: #438c79 url(../images/icon-img4.png) center center no-repeat;
  background-size: 35%;
}

.tickeprice {
  width: 80%;
  display: block;
  margin: 0 auto;
}
.tickeprice .type {
  padding-left: 32px;
  color: #335342;
  line-height: 30px;
  font-size: 16px;
  background: url(../images/cart-img4.png) left center no-repeat;
}
.tickeprice .listprice {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.tickeprice ul {
  list-style: none;
  text-align: center;
}
.tickeprice li {
  padding: 15px 0;
  width: 25%;
  display: inline-block;
  margin-right: 4%;
}
.tickeprice .selectticket {
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  padding: 42px 0 20px 0;
  color: #c9c9c9;
  background: url(../images/cart-img5.png) center center no-repeat;
  background-size: 60%;
}
.tickeprice p {
  font-size: 18px;
  color: #8d8d8d;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}
.tickeprice li.active {
  background: #dfeae7;
  border-radius: 10px;
}
.tickeprice li.active .selectticket {
  color: #deb13c;
  background: url(../images/cart-img5-hover.png) center center no-repeat;
  background-size: 60%;
}
.tickeprice li.active p {
  color: #438c79;
}
.tickeprice li:last-child {
  margin-right: 0;
}
.tickeprice .date {
  padding-left: 32px;
  margin-top: 20px;
  color: #335342;
  line-height: 30px;
  font-size: 16px;
  background: url(../images/date-cal.svg) left center no-repeat;
  background-size: 24px;
}
.tickeprice .note {
  margin-top: 20px;
  color: #000;
  line-height: 30px;
  font-size: 16px;
}
.tickeprice .date-cal {
  width: 280px;
  margin: 0 auto;
  margin-top: 20px;
}
.tickeprice .date-cal img {
  width: 100%;
}
.tickeprice .buttonColam {
  width: 100%;
  margin: 20px 0 0px 0;
  float: left;
}
.tickeprice .btn {
  padding: 10px 40px 10px 20px;
  text-align: center;
  margin-bottom: 30px;
  min-width: 150px;
  position: relative;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
  float: right;
  border-radius: 50px;
  display: block;
  background: #1b8e78;
}
.tickeprice .btn span {
  display: block;
  position: absolute;
  right: 45px;
  top: 22px;
  width: 9px;
  height: 9px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(-45deg);
}
.tickeprice .btn span::before {
  content: "";
  display: block;
  width: 2px;
  height: 14px;
  background-color: #fff;
  transform: rotate(-45deg) translate(4px, -2px);
  left: 0;
  top: 0;
}

.tickeprice {
}

.footerBox {
  background: #5f5f5f;
  width: 100%;
  padding: 8px 0;
  float: left;
}
.footerBox .card {
  float: right;
}
.footerBox ul {
  list-style: none;
}
.footerBox li {
  padding-right: 15px;
  float: left;
}
.footerBox .copyright {
  color: #fff;
  float: left;
  line-height: 30px;
  font-weight: 500;
  font-size: 12px;
  font-family: "Segoe UI";
}

.mb0 {
  margin-bottom: 0 !important;
}

.m-bottom_10 {
  margin-bottom: 10px;
}
.m-bottom_20 {
  margin-bottom: 20px;
}
.m-bottom_30 {
  margin-bottom: 30px;
}
.m-bottom_40 {
  margin-bottom: 40px;
}

.m-top_10 {
  margin-top: 10px;
}
.m-top_20 {
  margin-top: 20px;
}
.m-top_30 {
  margin-top: 30px;
}
.m-top_40 {
  margin-top: 40px;
}

.m-left_10 {
  margin-left: 10px;
}
.m-left_20 {
  margin-left: 20px;
}
.m-left_30 {
  margin-left: 30px;
}
.m-left_40 {
  margin-left: 40px;
}

.m-right_10 {
  margin-right: 10px;
}
.m-right_20 {
  margin-right: 20px;
}
.m-right_30 {
  margin-right: 30px;
}
.m-right_40 {
  margin-right: 40px;
}

.p-bottom_0 {
  padding-bottom: 0px !important;
}
.p-bottom_10 {
  padding-bottom: 10px;
}
.p-bottom_20 {
  padding-bottom: 20px;
}
.p-bottom_30 {
  padding-bottom: 30px;
}
.p-bottom_40 {
  padding-bottom: 40px;
}

.p-top_10 {
  padding-top: 10px;
}
.p-top_20 {
  padding-top: 20px;
}
.p-top_30 {
  padding-top: 30px;
}
.p-top_40 {
  padding-top: 40px;
}

.p-left_10 {
  padding-left: 10px;
}
.p-left_20 {
  padding-left: 20px;
}
.p-left_30 {
  padding-left: 30px;
}
.p-left_40 {
  padding-left: 40px;
}

.p-right_10 {
  padding-right: 10px;
}
.p-right_20 {
  padding-right: 20px;
}
.p-right_30 {
  padding-right: 30px;
}
.p-right_40 {
  padding-right: 40px;
}

.p5 {
  padding: 5px;
}
.brn {
  border: none !important;
  padding-top: 44lpx;
}

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-70 {
  width: 70%;
}
.w-25 {
  width: 25%;
}
.w-33 {
  width: 33.3%;
}
.w-60 {
  width: 60%;
}

/* #Media Queries
================================================== */

@media only screen and (max-width: 1150px) {
  .servicesBox li {
    padding-right: 3%;
  }
}

@media only screen and (max-width: 1023px) {
  .bannerBox .pricecolam {
    width: 100%;
    padding: 0 10px;
  }
  .bannerBox .ticket {
    font-size: 36px;
  }
  .bannerBox .ticket {
    margin-right: 6%;
  }
  .bannerBox .daycolam {
    margin-right: 4%;
  }
  .bannerBox .buyBTN a {
    padding: 0 20px;
  }
  .bannerBox .pricecolam {
    top: 29%;
  }
  .travelBox .panel {
    width: 38%;
  }
  .travelBox .left {
    width: 22%;
  }
  .travelBox .right {
    width: 75%;
  }
  .travelBox h3 {
    font-size: 18px;
  }
  .travelBox p {
    font-size: 14px;
    line-height: 27px;
  }
  .travelBox .centerimg {
    background-size: 30%;
  }
  .templeBox .list {
    margin: 0 -10px;
  }
  .templeBox li {
    padding: 0 10px;
  }
  .templeBox .inner p {
    font-size: 15px;
    line-height: 26px;
  }
  .templeBox h5 {
    font-size: 17px;
    font-weight: 600;
    line-height: 26px;
  }
  .servicesBox li {
    width: 50%;
    margin-bottom: 20px;
  }
  .leftNav li {
    padding: 15px 11px;
    font-size: 15px;
  }
  .selectBox li {
    width: 220px;
    height: 220px;
  }
  .cartBox li {
    width: auto;
  }
  .cartBox .ticket span {
    width: 30px;
    height: 30px;
  }
  .cartBox .ticket p {
    padding-left: 35px;
    line-height: 32px;
  }
  .cartBox .line {
    bottom: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .bannerBox {
    margin-top: 0;
    height: 40%;
  }
  .headercol .logo {
    width: 115px;
    margin: 5px 0;
  }
  .headercol .logo img {
    width: 100%;
  }
  .naviBox {
    padding: 10px 5px;
    position: relative;
    width: auto;
    float: right;
    background: none;
  }
  .footerBox .copyright {
    text-align: center;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .naviBox .menu {
    padding-top: 0px;
    position: fixed;
    left: 0;
    top: 53px;
    height: 92%;
    z-index: 99;
    width: 100%;
    background: #2a2a2a;
  }
  .naviBox ul {
    padding: 0;
    width: 100%;
  }
  .naviBox li {
    width: 100%;    
    box-sizing: border-box;
    line-height: 36px;
    border-bottom: 1px solid #424141;
  }
  .naviBox li:hover {
    background: #000;
  }
  .naviBox li a {
    color: #fff;
  }
  .naviBox li:hover::after,
  .naviBox li.active::after {
    height: 36px;
  }
  .naviBox .linkbar {
    display: none;
  }
  .headercol {
    height: auto;
    padding: 0px;
  }
  .headercol {
    border-bottom: 2px solid #00327a;
  }
  .headercol.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .headercol .rightside {
    display: none;
  }
  .travelBox .panel {
    width: 100%;
  }
  .travelBox .left.ml {
    margin-left: 0;
  }
  .travelBox .left {
    width: 60px;
  }
  .travelBox .left img {
    width: 100%;
  }
  .travelBox .right {
    width: 83%;
  }
  .travelBox .centerimg {
    background: none;
  }
  .travelBox .colam:last-child {
    border-bottom: 1px solid #d5d5d5;
  }
  .templeBox .list {
    margin: 0;
  }
  .templeBox li {
    padding: 0;
    width: 100%;
  }
  .servicesBox li {
    width: 100%;
    padding-right: 0;
  }
  .bannerBox .days::before {
    border-width: 7px 39px 0 39px;
    bottom: -7px;
  }
  .bannerBox .days {
    padding: 7px 12px 2px 12px;
    font-size: 16px;
    line-height: 18px;
  }
  .bannerBox .price {
    padding: 12px 0 4px 0;
    font-size: 18px;
    line-height: 24px;
  }
  .bannerBox .ticket::before {
    right: -12px;
    border-width: 33px 0 33px 12px;
  }
  .bannerBox .ticket {
    font-size: 21px;
    line-height: 23px;
    padding: 10px;
  }
  .footerBox .copyright {
    font-size: 11px;
    margin-top: 10px;
  }
  .bannerBox .daycolam {
    margin-right: 2%;
  }
  .bannerBox .pricecolam {
    width: 475px;
  }
  .bannerBox .buyBTN {
    margin-top: 18px;
    float: left;
  }
  .bannerBox .buyBTN a {
    padding: 0 9px;
    font-size: 18px;
    line-height: 36px;
  }
  .leftpanel {
    position: fixed;
    width: 96%;
    height: 100%;
    min-height: auto;
    top: 53px;
    left: 2%;
    z-index: 999;
    display: none;
    border-radius: 0px;
  }
  .main {
    height: auto;
  }
  .rightpanel {
    width: 100%;
    min-height: auto;
    height: auto;
    float: left;
    overflow: inherit;
  }
  .centerCol,
  #mainCntr {
    height: auto;
  }
  .headercol.header {
    margin-bottom: 15px;
  }
  .purchesBox .order {
    width: 100%;
    float: left;
  }
  .purchesBox .colR {
    padding: 5px 2px;
  }
  .purchesBox .cart {
    padding: 6px;
    width: 30px;
  }
  .purchesBox h3 {
    line-height: 26px;
    margin-bottom: 15px;
  }
  .selectBox li {
    margin-bottom: 20px;
    width: 100%;
  }
  .selectBox {
    margin-bottom: 0;
  }
  .selectBox h3 {
    font-size: 16px;
  }
  .headercol.header .naviBox {
    display: block;
  }
  .cartBox .cancel {
    text-indent: -9999px;
  }
  .cartBox .topcolam p {
    width: 92%;
    text-align: right;
  }
  .cartBox .ticket span {
    width: 40px;
    height: 40px;
  }
  .cartBox .ticket p {
    padding-left: 45px;
    line-height: 32px;
    text-indent: -9999px;
  }
  .cartBox .line {
    bottom: 13px;
  }
  .cartBox li {
    width: 32%;
  }
  .cartBox .ticket {
    padding: 0 10px 0 10px;
  }
  .tickeprice .listprice {
    width: 212px;
    margin: auto;
  }
  .tickeprice .selectticket,
  .tickeprice li.active .selectticket {
    background-size: 45%;
  }
  .tickeprice li {
    width: 100%;
    padding: 7px 0;
    margin-bottom: 15px;
  }
  .tickeprice .type {
    margin-bottom: 30px;
  }
  .contentBox p,
  .templeBox p,
  .DisclaimerBox p {
    line-height: 26px;
  }
  .headercol .menuhum {
    width: 40px;
    height: 40px;
    float: right;
    margin-left: 15px;
    position: relative;
    display: block;
    background: #00a650;
  }
  .menuhum .icon-left,
  .menuhum .icon-left:before,
  .menuhum .icon-left:after,
  .menuhum .icon-right,
  .menuhum .icon-right:before,
  .menuhum .icon-right:after {
    transition-duration: 0.5s;
    position: absolute;
    height: 3px;
    width: 15px;
    background-color: #fff;
  }
  .menuhum .icon-left {
    top: 20px;
    left: 5px;
  }
  .menuhum .icon-left:before {
    content: "";
    top: -9px;
  }
  .menuhum .icon-left:after {
    content: "";
    top: 9px;
  }
  .menuhum .icon-right {
    top: 20px;
    left: 20px;
  }
  .menuhum .icon-right:before {
    content: "";
    top: -9px;
  }
  .menuhum .icon-right:after {
    content: "";
    top: 9px;
  }
  .menuhum.fixed .icon-left {
    transition-duration: 0.5s;
    background: transparent;
    left: 7px;
  }
  .menuhum.fixed .icon-left:before {
    transform: rotateZ(45deg) scaleX(1) translate(3px, 3px);
  }
  .menuhum.fixed .icon-left:after {
    transform: rotateZ(-45deg) scaleX(1) translate(3px, -3px);
  }
  .menuhum.fixed .icon-right {
    transition-duration: 0.5s;
    background: transparent;
    left: 17px;
  }
  .menuhum.fixed .icon-right:before {
    transform: rotateZ(-45deg) scaleX(1) translate(-3px, 3px);
  }
  .menuhum.fixed .icon-right:after {
    transform: rotateZ(45deg) scaleX(1) translate(-3px, -3px);
  }
}

@media only screen and (max-width: 475px) {
  .travelBox .left {
    width: 40px;
  }
  .travelBox .right {
    width: 80%;
  }
  .bannerBox .pricecolam {
    width: 100%;
  }
  .bannerBox .ticket {
    font-size: 16px;
    line-height: 18px;
    padding: 7px 5px;
    margin-right: 3%;
  }
  .bannerBox .ticket::before {
    border-width: 25px 0 25px 8px;
    right: -8px;
  }
  .bannerBox .days {
    padding: 7px 6px 3px 6px;
    line-height: 13px;
    font-weight: 600;
    font-size: 13px;
  }
  .bannerBox .days::before {
    border-width: 4px 26px 0 26px;
    bottom: -4px;
  }
  .bannerBox .price {
    padding: 4px 0 1px 0;
    line-height: 24px;
  }
  .bannerBox .pricecolam {
    width: 315px;
    padding: 0 3px;
  }
  .bannerBox .buyBTN a {
    padding: 0 6px;
    font-size: 13px;
    line-height: 36px;
    font-weight: 600;
  }
  .bannerBox .buyBTN {
    margin-top: 11px;
    float: right;
  }
  .purchesBox .colR {
    width: 47%;
  }
  .cartBox .topcolam p {
    width: 85%;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }
  .tickeprice {
    width: 100%;
  }
  .bannerBox img {
    min-height: 120px;
  }
}

/**** additional ***/
.marLeft20 {
  margin-left: 20px;
}
.marRight20 {
  margin-right: 20px;
}
.shadow {
  box-shadow: 0px 0px 10px #9a9a9a;
  border: #fff solid 1px;
}
.ul-1 {
  position: relative;
}

.ul-1 ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ul-1 ul li {
  position: relative;
}

.ul-1 ul li a {
  font-size: 15px;
  color: #00a650;
  padding: 10px 25px;
  font-weight: 700;
  display: block;
  float: left;
  border: 1px solid #000;
  margin-right: 10px;
  text-decoration: none;
}

.ul-1 ul li a:hover {
  background: #000;
  color: #fff;
}

.clear {
  clear: both;
}
.moreBtn {
  font-size: 18px;
  fload: left;
  display: block;
}
.moreBtn a {
  font-size: 18px;
  color: #fff;
  background: #00a650;
  padding: 8px 15px;
}
.moreBtn a:hover {
  background: #000;
  color: #fff;
}

/* css */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
